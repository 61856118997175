.contact-selector__contact-count {
	padding-left: 25px;
	padding-top: 0px;
	padding-bottom: 10px;
	margin-right: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.contact-selector__contact-count .checkbox-container {
	top: -5px;
}

.contact-selector__contact-count__num-selected {
	margin-left: 12px;
}

.btn.contact-selector__contact-count__clear-btn {
	cursor: pointer;
	margin: 0 0 0 12px;
}

.contact-selector__contact-count__clear-btn {
	font-size: 12px;
	padding: 4px;
	border-radius: 5px;
	height: 28px;
}

.contact-selector-select-contacts-btn-container {
	display: inline-block;
}

.contact-selector__filters,
.contact-selector__update-contacts {
	padding-top: 5px;
	padding-bottom: 15px;
}

.contact-selector__filters-header,
.contact-selector__update-contacts-header {
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	width: 100%;
}

.contact-selector__update-contacts-header__icon {
	position: relative;
	top: -3px;
	left: 5px;
}

.contact-selector__update-contacts-header__icon:hover {
	cursor: pointer;
	opacity: 0.6;
}

.contact-selector__filters-column > div:first-child,
.contact-selector__update-contacts-column > div:first-child {
	margin: 0;
}

.contact-selector__filters-column > div {
	margin: 10px 0 0 0;
}
.contact-selector__update-contacts-column > div {
	margin: 10px 0 0 0;
}

.contact-selector__update-contacts__confirm {
	margin-top: 10px;
	display: flex;
	flex-direction: column;
}

.contact-selector__update-contacts__confirm-btns {
	display: flex;
	justify-content: flex-end;
}

.contact-selector__update-contacts__confirm-btns button {
	margin: 5px;
}

.contact-selector__selected-contacts-container {
	display: flex;
	flex-direction: row;
	align-content: stretch;
	height: 300px;
	max-height: 300px;
	overflow: hidden;
}

.contact-selector__selected-contacts {
	flex: 1 0 0;
	overflow-x: hidden;
}

.contact-selector__selected-contacts__none {
	margin: 10px 0;
	width: 100%;
}

.contact-selector__selected-contact {
	padding: 5px;
	flex: 1;
	overflow: auto;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	flex-wrap: wrap;
	width: 100%;
}

.contact-selector__selected-contact:hover {
	background-color: #f6f6f9;
	cursor: pointer;
}

.contact-selector__selected-contact > div {
	max-width: 46%;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.contact-selector__selected-contact .contact-selector__selected-contact-x svg {
	display: none;
}

.contact-selector__selected-contact:hover .contact-selector__selected-contact-x svg {
	display: inline-block;
}

.contact-selector__selected-contact-x-container {
	height: 5px;
	width: 5px;
}

.contact-selector__selected-contact-x svg {
	position: relative;
	right: 2px;
	top: 2px;
	margin: -1px 3px;
	width: 15px;
	height: 15px;
	color: rgb(236, 71, 89);
}

.contact-selector__selected-contact-x:hover svg {
	color: rgb(199, 54, 71);
}

.contact-selector__row-btns {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	flex-wrap: wrap;

	margin-top: 20px;
	margin-bottom: 30px;
}

.sweet-overlay {
	z-index: 1999999;
}

.sweet-alert {
	z-index: 1999999;
}

.contact-selector__update-contact-tags__feedback {
	height: 20px;
}

.contact-selector__update-contact-tags__feedback svg {
	position: relative;
	top: 3px;
}

.contact-selector__select-all {
	margin-left: 40px;
	margin-bottom: 10px;
	display: flex;
	justify-content: flex-start;
	align-items: center;
}

.contact-selector__select-all span {
	color: #60a9ff;
	font-weight: 600;
	opacity: 0.6;
	margin-right: 25px;
	margin-left: 10px;
}

.contact-selector__select-all span:hover {
	cursor: pointer;
	opacity: 1;
}

.contact-selector__space-between {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

@media (max-width: 767px) {
	.contact-selector__tab button {
		margin: 5px 0px 5px !important;
	}
}

.contacts-search__actions {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

.contact-selector__filters #datepicker {
	position: fixed;
}
.contact-selector__filters .InputFromTo .DayPickerInput-OverlayWrapper {
	margin-left: -50px;
}

.contacts-search__actions__send-message__dropdown {
	position: relative;
	margin-right: 10px;
}

.contacts-search__actions__send-message__dropdown-button {
	display: flex !important;
	align-items: center;
}

.contacts-search__actions__send-message__dropdown-button__text {
	padding-top: 2px;
}

.contacts-search__actions__send-message__dropdown-button__icon {
	margin-left: 8px;
	margin-right: -2px;
}

.contact-selector__update-contacts__checkbox__option__label {
	margin-left: 35px;
	margin-bottom: 5px;
}
