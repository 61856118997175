.send-nps-request-modal {
	min-width: 390px;
	width: 300px;
}

.send-nps-request-modal .modal__content__header {
	font-size: 23px;
	margin-bottom: 0;
}

.send-nps-request-modal .modal__field:first-child {
	margin-top: 0;
}

#final-nps-send-invite {
	margin: 6px auto 6px;
	width: 80%;
	margin-top: 20px;
}

@media (max-width: 500px) {
	.send-nps-request-modal {
		width: 100%;
		min-width: 200px;
		max-width: 300px;
	}
}

.nps__langauge_select {
	width: 100%;
}

.nps-language-field {
	width: 100%;
}
