
.mb-media__file {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    width: 100%;
}

.mb-media__file__title {
    font-size: 14px;
}

.mb-media__file__file_extension {
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 10px;
    user-select: none;
}

.mb-media__video:focus {
    outline: none;
}

.mb-media__image {
    border-radius: 8px;
}

.mb-media__video {
    border-radius: 8px;
    width: 100%;
}

.mb-media__audio {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    min-height: 100px;
    height: 100%;
    width: 100%;
    gap: 1rem;
    position: relative;
}

.mb-media__audio__wave {
    width: 100%;
    height: 90px;
    margin-right: 10px;
}

.mb-media__audio__wave:hover {
    cursor: pointer;
    opacity: 0.7;
}

.mb-media__audio__wave > * {
    overflow-x: hidden !important;
}

.mb-media__audio__wave--hide {
    display: none;
}

.mb-media__audio__button {
    margin: 0 5px 0 15px;
    color: #626262;
}

.mb-media__audio__button:hover {
    opacity: 0.7;
    cursor: pointer;
    transition: background 0.2s, border 0.2s;
}

.mb-media__audio__spinner {
   position: absolute;
   margin-left: 45%;
}

.mb-media__audio__download {
    position: absolute;
    margin-left: 25%;
    width: 100%;
    display: flex;
    align-items: center;
}

.mb-media__audio__download__text {
    margin-right: 10px;
    margin-top: 5px;
}

.mb-media__audio__error {
    width: 100%;
    display: flex;
    align-items: center;
}

.mb-media__audio__error__text {
    margin-right: 10px;
}