.user-profile {
	width: 35px;
	height: 35px;
	border-radius: 50px;
	overflow: hidden;
	background-color: #f2f2ff;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

.user-profile:hover {
	cursor: pointer;
}

.user-profile__initials {
	font-weight: 900;
	padding-top: 3px;
}

.user-profile__img {
	width: 35px;
	height: 35px;
}

.user-profile__options {
	position: absolute;
	top: 40px;
	right: 10px;
	width: 270px;
	max-height: 500px;
	background-color: white;
	border-radius: 5px;
	padding: 15px 0 15px 0;
	overflow: scroll;
	color: #333;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	z-index: 1001;
}

.user-profile__options::-webkit-scrollbar {
	display: none;
}

.user-profile__options__item {
	width: 100%;
	height: 40px;
	font-size: 15px;
	margin-bottom: 5px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	flex-shrink: 0;
}

.user-profile__options__item__icon {
	margin-left: 20px;
	margin-right: 20px;
	margin-top: 4px;
}

.user-profile__options__item__title {
	margin-top: 2px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.user-profile__options__item:hover {
	cursor: pointer;
	background-color: rgba(96, 169, 255, 0.2);
	transition: background 0.2s, border 0.2s;
}

.user-profile__options__divider {
	margin: 15px 0px 15px 0px;
	width: 90%;
	background-color: #e4e9f0;
	height: 1px;
	align-self: center;
}

.user-profile__options__name {
	margin-top: 5px;
	padding: 0px 15px;

	font-size: 20px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 100%;
}

.user-profile__options__status {
	margin-top: 3px;
	padding: 0px 15px;

	font-size: 14px;
	width: 100%;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
}

.user-profile__options__status__indicator {
	border-radius: 50px;
	width: 10px;
	height: 10px;
	z-index: 1000 !important;
}

.user-profile__options__status__indicator--float {
	right: 10px;
	top: 5px;
	position: absolute;
}

.user-profile__options__status__indicator--inline {
	margin-right: 6px;
	margin-bottom: 3px;
}

.user-profile__options__status__indicator--available {
	background-color: #34df12;
}

.user-profile__options__status__indicator--away {
	background-color: white;
	border: 1px solid #333;
}

.user-profile__options__connection {
	margin-top: 10px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	align-self: center;

	color: #9d9e9d;
}

.user-profile__options__connection__indicator {
	border-radius: 50px;
	width: 8px;
	height: 8px;
	z-index: 1000 !important;
	margin-right: 6px;
	margin-bottom: 3px;
}

.user-profile__options__connection__indicator--connected {
	background-color: #34df12;
}

.user-profile__options__connection__indicator--reconnecting {
	background-color: #f0d86c;
}

.user-profile__options__copyright {
	width: 90%;

	color: #9d9e9d;

	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	justify-content: center;

	font-size: 12px;
	align-self: center;
}
