.hosted-messaging__check-phone-importability {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	flex-wrap: wrap;
}

.hosted-messaging__check-phone-importability-input {
	width: 400px;
	margin: 5px 10px 5px 0;
}

.hosted-messaging__check-phone-importability-input svg {
	height: 14px;
	width: 14px;
	position: relative;
	top: 2px;
}

.hosted-messaging__check-phone-importability-check-btn {
	height: 35px;
	margin: 5px 10px 5px 0;
}

.hosted-messaging__check-phone-importability-icon {
	height: 20px;
	font-size: 16px;
	margin-top: 10px;
}

.hosted-messaging__check-phone-importability-icon svg {
	height: 16px;
	width: 16px;
	position: relative;
	top: 2px;
}

.hosted-messaging__check-phone-importability-icon .success {
	color: #18a689;
}

.hosted-messaging__check-phone-importability-icon .fail {
	color: #ed5565;
}

#phone-number-tooltip {
	width: 300px;
}

.hosted-messaging__import-number {
	margin-top: 15px;
}

.hosted-messaging__warning {
	color: red;
	margin-top: 30px;
}
