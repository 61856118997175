.languages-selector-mini {
	cursor: pointer;
	font-size: 12px;
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-end;
	position: relative;
}

.languages-selector-mini:hover {
	cursor: pointer;
}

.languages-selector-mini__control {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-end;
}

.languages-selector-mini__icon_container {
	padding-right: 7px;
	padding-top: 1px;
	user-select: none;
}

.languages-selector-mini__language_code {
	padding-right: 5px;
	padding-top: 2px;
	user-select: none;
	font-weight: 800;
}

.languages-selector-mini__language_arrow {
	height: 100%;
}

.languages-selector-mini__languages_list--hidden {
	display: none !important;
}

.languages-selector-mini__languages_list {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	position: absolute;
	bottom: calc(100% + 7px);
	background: #fcfcfc;
	padding-left: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
	padding-right: 10px;
	border-radius: 7px;
	border: 1px solid #d9d9d9;

	left: 0px;
	z-index: 999999;
	white-space: nowrap;
}

.languages-selector-mini__languages_list__row {
	display: flex;
	flex-direction: row;
	align-items: center;
	align-self: start;
	padding-top: 4px;
	padding-bottom: 4px;
	padding-left: 5px;
	padding-right: 5px;
	width: 100%;
}

.languages-selector-mini__languages_list__row:hover {
	background: #dcdcdc;
}

.languages-selector-mini__languages_list__row__icon_container {
	padding-right: 7px;
}

.languages-selector-mini__languages_list__row__language_code {
	margin-top: 2px;
}
