/* eaiam = edit-ai-agent-modal */

.eaiam {
    width: 800px;
    height: 690px;
}

.eaiam__sources {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    
    width: calc(100% - 40px);
}

.eaiam__sources__types {
    background-color: #f6f6f9;
    border-radius: 8px;
    padding: 10px 0px;
    margin-right: 10px;
    
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.eaiam__sources__types__title {
    font-size: 16px;
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 15px;
}

.eaiam__sources__types__item {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    
    height: 35px;
    width: 185px;
    
    margin: 2px 7px;
    margin-bottom: 3px;
    padding: 2px 10px 0px 10px;
    
    font-size: 14px;
    border-radius: 10px;
    border: 1px solid #f6f6f9;
}

.eaiam__sources__types__item:hover {
    background-color: rgba(96, 169, 255, 0.2);
    cursor: pointer;
    transition: background 0.3s, border 0.3s;
    border: 1px solid #60a9ff;
}

.eaiam__sources__types__item--active {
    background-color: rgba(96, 169, 255, 0.2);
    color: black;
}

.eaiam__sources__web {
    width: 50%;
}

.eaiam__sources__web__page {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.eaiam__settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    margin: 20px;
}

.eaiam__settings__system-prompt {
    height: 300px;
}

.eaiam__sources__types__train {
    margin-left: auto;
    margin-right: 10px;
    margin-top: 20px;   
}