.new-actions__trigger {
	height: 34px;
	padding: 0 12px;

	background-color: #60a8ff;
	border-radius: 30px;
	border: 2px solid #60a9ff;

	color: white;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
}

.new-action__trigger__icon {
	padding: 14px 10px 0px 0px;

	transform: rotate(45deg);
}

.new-action__trigger__title {
	padding: 2px 7px 0px 0px;
	font-size: 14px;

	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.new-actions__trigger:hover {
	cursor: pointer;

	background-color: white;

	color: #60a9ff;
	cursor: pointer;
	transition: background 0.3s, color 0.3s;
}
