.record-audio {
	position: relative;
	display: flex;
	align-items: center;
	border: 1px solid #e7eaec;
	border-radius: 8px;
	padding: 15px 20px;
}

.record-audio__recording {
	display: flex;
	align-items: center;
}

.record-audio__text {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 48px;
}

.record-audio__wave {
	width: 100%;
	padding: 0 0 0 15px;
}

.record-audio__wave--hide {
	display: none;
}

.record-audio .mb-media__audio__button {
	margin-left: 0;
	display: flex;
	align-items: center;
}

.record-audio .mb-media__audio__wave {
	margin-right: 0;
}
