.chatbot__container {
    width: calc(100% - 40px);
    height: calc(100% - 70px);
    
    margin: 20px;
    padding: 15px;

    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background-color: #1e1e2e;
    color: #fff;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.chatbot__messages {
    width: 100%;

    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    padding: 8px;
    
    border-radius: 8px;
    overflow-y: auto;
    background-color: #2a2a3a;
}

.chatbot__message {
    display: flex;

    margin: 8px 0;
    opacity: 0;
    
    transform: translateY(10px);
    animation: fadeInUp 0.3s forwards;
}

@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.chatbot__message--user {
    justify-content: flex-end;
}

.chatbot__message--assistant {
    justify-content: flex-start;
}

.chatbot__bubble {
    padding: 10px 14px;
    border-radius: 8px;
    max-width: 75%;
    font-size: 14px;
    line-height: 1.4;
}

.chatbot__bubble--user {
    background-color: #2563eb;
    color: white;
}

.chatbot__bubble--assistant {
    background-color: #4a4a5a;
    color: white;
}

.chatbot__bubble__blurb {
    margin: 0;
}

.chatbot__compose {
    width: 100%;
    
    display: flex;

    margin-top: 15px;
    
    background-color: #1e1e2e;
}

.chatbot__compose__input {
    flex: 1;

    margin-right: 15px;

    padding: 10px;
    border-radius: 8px;
    border: none;
    background-color: #2a2a3a;
    color: white;
    outline: none;
}

.chatbot__compose__button {

    width: 80px;

    padding: 15px 10px 14px 10px;
    border-radius: 8px;
    background-color: #2563eb;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.2s;
}

.chatbot__compose__button:hover {
    background-color: #1d4ed8;
}