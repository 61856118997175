.ai-agents {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;

    margin-left: 20px;
    width: 100%;

    /* background-color: aliceblue; */
}

.ai-agents__card {
    min-width: 125px;
    max-width: 125px;
    height: 100px;
    margin: 10px;
    border-radius: 8px;

    border: 1px solid #f6f6f9;
    background-color: #f6f6f9;

    padding: 10px
}

.ai-agents__card:hover {
    background-color: rgba(96, 169, 255, 0.2);
    cursor: pointer;
    transition: background 0.3s;
    border: 1px solid #60a9ff;
}

.ai-agents__card__title {
    font-size: 14px;
    font-weight: 600;
    margin-top: 5px;
    margin-bottom: 5px;
}

.ai-agents__card__action {
    font-size: 14px;
    font-weight: 400;
    margin-top: 5px;
    margin-bottom: 5px;
    text-align: center;
}