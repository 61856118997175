.mb-attachments {
    position: fixed;
    z-index: 1020;
    bottom: 0;
    min-height: 155px;
    background-color: white;
    margin: 10px 20px 55px 30px;
    border-radius: 8px;
    overflow-y: none;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    min-width: 200px;
    overflow: hidden;
    padding: 15px 50px 15px 15px;
}

.mb-attachments--placed{
    position: absolute;
}

.mb-attachments-overlay {
    width: 135px;
    height: 120px;
    position: fixed;
    opacity: 0;
    background-color: black;
    color: white;
    padding: 60px 60px;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;
    font-size: 16px;

    border-radius: 8px;
}

.mb-attachments__list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}

.mb-attachments__list .attachment,
.mb-attachments__list .attachment-container ,
.mb-attachments__list .attachment__overlay,
.mb-attachments__list .attachment__file {
    width: 135px;
    height: 120px;
}

.mb-attachments__list .attachment__file {
    background-color: #f1f2f6;
}

.mb-attachments__list .attachment-container {
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 0;
}

.mb-attachments__attachment {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 135px;
    height: 120px;
}

.mb-attachments__attachment:hover > .mb-attachments__overlay {
    transition: .2s;
    opacity: 0.7;
    cursor: pointer;
}

.mb-attachments-file {
    padding: 10px;
    font-size: 14px;
    width: 135px;
    height: 120px;

    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: space-evenly;
    text-align: center;

    background-color: #F1F2F6;
    white-space: break-spaces;
    word-break: break-all;
}

.mb-attachments__add {
    width: 135px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;

    border-style: dashed;
    border-color: #d8d9dd;
    border-image-slice: 4px;
    border-image-width: 3px;
    border-radius: 8px;
    border: 3px dashed;
    color: #d8d9dd;
    opacity: 0.6;
}

.mb-attachments__add--dragging {
    background: #60a8ff1c;
}

.mb-attachments__add:hover {
    cursor: pointer;
    opacity: 1;
}

.mb-attachments__x {
    position: fixed;
    top: 15px;
    right: 15px;
    height: 18px;
    width: 18px;
}

.mb-attachments__x:hover {
    cursor: pointer;
    opacity: 0.6;
}

.mb-attachments-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 8px;
}

.mb-attachments-input {
    display: none !important;
}
