.posts {
    margin: 0 30px 10px;
}

.posts__post {
    width: 600px;
    padding: 20px;
    margin-bottom: 20px;
    border: 1px solid #e7eaec;
    border-radius: 8px;
}

.posts__connect {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 600px;
}

.posts__connect__img {
    margin: 20px 0;
    width: 300px;
}

.posts__connect .mb-button {
    margin: auto;
}

.posts__post__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 10px;
}

.posts_post__header__actions {
    display: flex;
    align-items: center;
    position: relative;
}

.posts_post__header__actions__action {
    margin-left: 10px;
    margin-right: 0;
}

.posts__post__text {
    margin: 0 0 15px;
}

.posts__post__created {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #d8d9dd;
}

.posts__post__created__text {
    position: relative;

}

.posts__post__media {
}

.posts__post__media .attachment,
.posts__post__media__item {
    margin-right: 10px;
    margin-bottom: 10px;
}

.posts__post__media__item__img {
    width: 300px;
    border-radius: 8px;
}

.posts__post__action__button-label,
.posts__post__action__url-label,
.posts__post__action__label
{
    font-weight: 600;
}

/* manage posts modal */
.mpm__drag-n-drop {
    padding: 20px;
    /* border: 1px solid #e7eaec;
    border-radius: 8px; */
}