.mb-thread {
	width: 400px;
	flex-grow: 1;
	display: flex;
	flex-flow: column nowrap;
}

.mb-thread-welcome {
	width: 400px;
	flex-grow: 1;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	overflow: hidden;
	align-items: center;
}

.mb-thread-welcome-img {
	width: 60%;
	max-height: 250px;
	margin-bottom: 50px;
}

.mb-thread-welcome-title, 
.mb-thread-permission-denied-title {
	font-size: 28px;
	margin-top: 10px;
	text-align: center;
}

.mb-thread-welcome-subtitle,
.mb-thread-permission-denied-subtitle {
	font-size: 16px;
	margin: 10px;
	text-align: center;
}

.mb-thread-permission-denied {
	width: 550px;
	max-width: 550px;
	margin: auto;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;    
}

.mb-thread-permission-denied-img {
	height: 75px;
}

.mb-thread-permission-denied-img svg {
	width: 75px;
	height: 75px;
	color: #EC4758;
}

.mb-thread-header {
	padding-left: 30px;
	height: 70px;
	font-size: 24px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid #F1F1F1;
	z-index: 1001;
}

.mb-thread-header-name {
	flex: 1;
	min-width: 50px;
	display: flex;
}

.mb-thread-header-name .mb-edit-contact-name-text {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.mb-edit-contact-name {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

.mb-thread-header-name-members {
	font-size: 12px;
}

.mb-thread-header-name--unknown {
	font-style: italic;
}


.mb-thread-header-birthday{
	margin-left: 5px;
}

@media screen and (max-width: 1250px) {
	.mb-thread-header-medium {
		display: none;
	}
}

.mb-thread-header-medium {
	font-weight: 900;
	font-size: 11px;
	border-radius: 8px;
	padding: 7px 10px 4px 10px;
	line-height: 1.3;
	margin-right: 15px;
	border-width: 1px;
	border-style: solid;
}

.mb-thread-header-medium--sms {
	border-color: #27AE60;
	color: #27AE60;
}

.mb-thread-header-medium--facebook {
	border-color: #0084ff;
	color: #0084ff;
}

.mb-thread-header-medium--instagram {
	
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent; 
	-moz-background-clip: text;
	-moz-text-fill-color: transparent;
	
	background-size: 100%;
	background-repeat: repeat;
	
	border-color: #dc2743;
	background-image: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
	
}

.mb-thread-header-medium--google {
	border-color: #0084ff;
	color: #0084ff;
}

.mb-thread-header-medium--email {
	border-color: #ff6b81;
	color: #ff6b81;
}

.mb-thread-header-medium--web {
	border-color: #60A9FF;
	color: #60A9FF;
}

.mb-thread-header-medium--secure {
	border-color: #6f00ff;
	color: #6f00ff;
}

.mb-thread-header-action--auto {
	margin-left: auto;
}

.mb-thread-header-action--team-chat-details {
	margin-left: 10px;
}

.mb-thread-header-action {
	margin-right: 30px;
}

.mb-thread-header-action:hover {
	opacity: 0.8;
	cursor: pointer;
}

.mb-thread-header-action--pin {
	margin-right: 0px;
}

.mb-thread-list {
	flex-grow: 1;
	overflow-y: scroll;
	height: calc(100vh - 310px);
	z-index: 1000;
}

.mb-thread-list-info {
	text-align: center;
	margin-top: 20px;
	margin-bottom: 0px;
	font-size: 14px;
	font-style: italic;
}

.mb-thread-list-conversation {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	width: 100%;
}

.mb-thread-compose {
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-end;
}

.mb-thread-compose--attachmentOverlay {
	width: auto;
	margin: 0 30px;
}

.mb-thread-compose__disabled {
	width: calc(100% - 20px);
	height: 100px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: center;
	background-color: #F6F6F9;
	border-radius: 8px;
	margin: 10px;
	padding-left: 20px;
	padding-right: 20px;
}

.mb-thread-compose__input {
	border-radius: 8px;
	border: 1px solid #E6E6E6;
	width: 100%;
	margin: 0px 30px;
	margin: 0;
	font-size: 18px;
	padding: 12px 12px;

	display: flex;
	flex-flow: column nowrap;
	position: relative;
}

.mb-thread-compose__input--disabled {
	background-color: #fafafa;
}

.mb-thread-compose__input__textarea {
	line-height: 24px;
	font-size: 16px;
	resize: none;
	border: none;
	width: 100%;
	padding: 0;
	flex-grow: 1;
	background-color: inherit;
}

.mb-thread-compose__input__signature {
	position: absolute;
	left: 15px;
	bottom: -15px;
	font-size: 12px;
	color: #9b9b9b;
	background-color: white;
	border: 1px solid #E6E6E6;
	padding: 6px 8px 2px 8px;
	border-radius: 8px;
	
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.mb-thread-compose-actions {
	margin-left: 40px;
	margin-right: 30px;
	height: 80px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.mb-thread-compose-active-actions {
	display: flex;
	flex-direction: row-reverse;
	margin-bottom: 3px;
}

.mb-thread-compose-action {
	margin-right: 30px;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
}

.mb-thread-compose-action--disabled {
	opacity: 0.8;
	margin-right: 30px;
}

.mb-thread-compose-action--auto {
	margin-left: auto;
}

.mb-thread-compose-action:hover {
	opacity: 0.8;
	cursor: pointer;
}

.mb-thread-compose-action--disabled {
	opacity: 0.5;
}

.mb-thread-list-footer {
	position: fixed;
	
	bottom: 190px;
	right: 30px;
	
	height: 40px;
	background-color: white;
	border-radius: 8px;
	padding: 15px 15px 33px 15px;
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
	font-size: 16px;
	z-index: 99999999;
}

.mb-thread-compose-send-close {
	height: 40px;
	font-size: 16px;
	border-radius: 8px;
	padding: 5px 10px;
	line-height: 1.6;
	margin-bottom: 5px;
	background-color: #F6F6F9;
	line-height: 35px;
	margin-right: 10px;
}


.mb-thread-compose-send-close:hover {
	opacity: 0.8;
	cursor: pointer;
}

.mb-thread-compose-send-later {
	height: 40px;
	font-size: 16px;
	border-radius: 8px;
	padding: 5px 10px;
	line-height: 1.6;
	margin-bottom: 5px;
	background-color: #F6F6F9;
	line-height: 35px;
	margin-right: 10px;
}

.mb-thread-compose-send-later:hover {
	opacity: 0.8;
	cursor: pointer;
}

/* Image viewer */
#viewerBackdrop {
	z-index: 20001;
}

.mb-thread__tasks {
	width: 100%;
	
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	background: #f6f6f9;
	
	padding-top: 20px;
	padding-bottom: 20px;
	
	padding-left: 20px;
	padding-right: 20px;
}

.mb-thread__tasks__title{
	width: 100%;
}

.mb-thread__tasks__row {
	width: 100%;
	padding-left: 20px;
}

.mb-thread__tasks__row__name {
	width: 100%;
}

.mb-thread__tasks__rows {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	
	width: 100%;
	
	max-height: 50px;
	overflow: scroll;
}

.mb-thread-views-container {
	z-index: 1000;
	position: fixed;
	top: 138px;
	right: 0px;
}

.mb-thread-views-items {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	margin-left: 30px;
}

.mb-thread-compose-actions__banner {
	border-radius: 8px;

	border: 2px solid #60a9ff;
	background-color: rgba(96, 169, 255, 0.2);
	height: 40px;
	padding: 0px 10px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	margin-left: auto;
	margin-bottom: 4px;
	margin-right: 15px;

	overflow: hidden;
}

.mb-thread-compose-actions__banner__icon {
	margin-top: 5px;
	margin-right: 4px;
}

.mb-thread-compose-actions__banner__text {
	margin-top: 3px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.mb-thread-compose-actions__banner__text__link {
	color: black;
}

.mb-thread-compose-actions__banner__close {
	margin-top: 6px;
	margin-left: 10px;
}

.mb-thread-compose-actions__banner__close:hover {
	cursor: pointer;
}

.mb-thread-compose-actions__banner:hover {
	cursor: pointer;

	background-color: #60a9ff;

	color: white;
	cursor: pointer;
	transition: background 0.3s, color 0.3s;
}

.mb-thread-compose-actions__spacer {
	margin-left: auto
}