.dh-top {
	padding-left: 10px;
	padding-right: 10px;
	min-height: 50px;
	background-color: white;
	border-bottom: 1px solid #f1f1f1;

	width: 100%;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;

	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	z-index: 1900;
}

.dh-top__nav-toggle {
	display: none;

	margin-right: 10px;
	margin-left: 10px;
	color: #333;
}

@media (max-width: 767px) {
	.dh-top__nav-toggle {
		display: flex;
	}
}

.dh-top__banner {
	border-radius: 8px;

	border: 2px solid #60a9ff;
	background-color: rgba(96, 169, 255, 0.2);
	height: 40px;
	padding: 0px 10px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	margin-left: 15px;
	margin-right: 15px;

	overflow: hidden;
}

.dh-top__banner__icon {
	margin-top: 5px;
	margin-right: 4px;
}

.dh-top__banner__text {
	margin-top: 3px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.dh-top__banner__text__link {
	color: black;
}

.dh-top__banner__close {
	margin-top: 6px;
	margin-left: 30px;
}

.dh-top__banner__close:hover {
	cursor: pointer;
}

.dh-top__banner:hover {
	cursor: pointer;

	background-color: #60a9ff;

	color: white;
	cursor: pointer;
	transition: background 0.3s, color 0.3s;
}

.dh-top__new-button {
	margin-right: auto;
	overflow: hidden;
}

.dh-top__review-button {
	margin-right: auto;
}

.dh-top__action:hover {
	cursor: pointer;
}

.dh-top__company-selector {
	margin-left: 10px;
	margin-right: 20px;
	color: #333;
}

.dh-top__location-selector {
	margin-right: 20px;
	color: #333;
}

.dh-top__account-expire {
	margin-left: 10px;
	margin-right: 10px;
	color: #ffffff;
	background-color: #e74c3c;
	border-radius: 8px;
	padding: 8px 20px;
	max-height: 50px;

	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.dh-top__action {
	margin-right: 20px;
	padding-top: 3px;
	color: #333;
	position: relative;
}

.dh-top__action__red-dot {
	right: 4px;
	top: 0px;
	position: absolute;
	background-color: #ec4758;
	border-radius: 50px;
	width: 8px;
	height: 8px;
}

.dh-top__action__red-dot--customer {
	top: 1px !important;
	right: -3px !important;
}

.dh-top__action__red-dot--team {
	top: 2px !important;
	right: -3px !important;
}
