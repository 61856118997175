/* bootstrap adds modal-open to the body when a modal is opened */
/* this change might help resolve the floating cursor problem on input fields on iOS */

/* TODO - At some point the the following styles need to be removed

	ibox-content
	page-heading
	widget
	btn-success
	btn-success:hover

*/

@font-face {
	font-family: demandhub-montserrat-regular;
	src: url("https://cdn.demandhub.co/assets/fonts/MontserratRegular.woff2") format("woff2"),
		url("https://cdn.demandhub.co/assets/fonts/MontserratRegular.woff2") format("woff");
	font-weight: normal;
	font-style: normal;
}

.ibox-content {
	background-color: white;
	padding: 20px;
}

.page-heading {
	background-color: white;
	padding: 0px 30px 10px 30px;
	margin-bottom: 20px;
}

.widget {
	background-color: white;
}

.btn-success {
	background-color: #60a9ff !important;
	border: 1px solid #60a9ff !important;
}

.btn-success:hover {
	background-color: #2973c9 !important;
	border: 1px solid #2973c9 !important;
}

.label-success {
	background-color: #4977dd !important;
	border: 1px solid #4977dd !important;
	color: white;
}

::-webkit-scrollbar {
	width: 6px;
	height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
	background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #e7e7ee;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	cursor: pointer;
	background-color: rgba(96, 169, 255, 0.5);
	transition: background 0.2s, border 0.2s;
}
::-webkit-scrollbar-thumb:hover ::-webkit-scrollbar {
	width: 10px;
}

.modal-open {
	position: fixed;
	width: 100%;
	height: 100%;
	overflow: visible; /* supposed to prevent scrolling to top when bootstrap modal is opened */
}
body {
	-ms-overflow-style: scrollbar !important;
	background-color: #ffffff;
}
.select-group-btn {
	vertical-align: top;
}
.btn-space-right {
	margin-right: 10px;
}

.btn-space-between {
	margin-left: 10px;
}
.h-1 {
	font-size: 36px;
}
.label-green {
	background: #4caf50;
	color: white;
}
.btn-green {
	background: #4caf50;
	color: white;
}
.btn-animate:hover {
	color: white;
	display: inline-block;
	transform: scale(1.03);
	transition: 0.5s;
}
.btn-green:active {
	transform: translateY(2px);
}
.btn-green:focus {
	color: white;
}
.stars-flexbox-container {
	display: flex;
	flex-direction: column;
	padding-right: 15px;
	padding-left: 15px;
	padding-top: 5px;
}

.stars-flexbox-children {
	width: 100%;
	cursor: pointer;
	margin-bottom: 20px;
}
.App__col-xs-2 {
	width: 200px;
}
.App__p-xlg {
	padding: 30px;
}
.App__element--inline {
	display: inline;
}
@media screen and (max-width: 768px) {
	.nav-mobile-align-right {
		text-align: right;
		margin-right: 0px;
	}
}

.multi-location-selector {
	margin-top: 20px;
}

.media {
	overflow: visible;
}

.media-body {
	overflow: visible;
}

.review-site-logo {
	margin-right: 20px;
}

.review-site-logo img {
	width: 40px;
	object-fit: contain;
}

.review-footer {
	margin-top: 20px;
}

.review-respond {
	float: left;
	margin-left: 5px;
}

.review-tag-dropdown {
	display: inline-block;
	position: relative;
	margin-left: 5px;
}

.review-star-yellow {
	color: #ecb904;
}

.review-star-empty {
	color: #ecb904;
}

#messenger-header-notification {
	-webkit-animation-duration: 3s;
	-webkit-animation-delay: 2s;
	-webkit-animation-iteration-count: infinite;
	-webkit-timing-function: ease-out;

	-webkit-animation-duration: 6s;
	-webkit-animation-delay: 2s;
	-webkit-animation-iteration-count: infinite;
	-webkit-timing-function: ease-out;

	-webkit-animation-duration: 3s;
	-webkit-animation-delay: 2s;
	-webkit-animation-iteration-count: infinite;
	-webkit-timing-function: ease-out;
}

.label {
	margin-top: 2px;
}

.ibox-title {
	box-shadow: 0 6px 8px rgba(102, 119, 136, 0.03), 0 1px 2px rgba(102, 119, 136, 0.3); /*0px 0px 45px -10px rgba( 178, 178, 178, 0.7);*/
	border: solid 0px #ecf0f1;
	border-radius: 0px;
	margin-bottom: 10px;
}

.ibox-content {
	box-shadow: 0 6px 8px rgba(102, 119, 136, 0.03), 0 1px 2px rgba(102, 119, 136, 0.3);
	border: solid 0px #ecf0f1;
	border-radius: 0px;
}

.leaderboard-nav {
	padding-left: 15px;
	margin-bottom: 0;
	list-style: none;
}

.leaderboard-nav:before {
	display: table;
	content: " ";
}

.leaderboard-nav:after {
	clear: both;
	display: table;
	content: " ";
	margin-bottom: 10px;
}

.leaderboard-nav > li {
	float: left;
	position: relative;
	display: block;
}

.leaderboard-nav > li > a {
	color: #676a6c;
	font-weight: 600;
	font-size: 13px;
	padding: 10px 14px 10px 0px;
}

.leaderboard-nav > li.active > a,
.leaderboard-nav > li.active > a:focus,
.leaderboard-nav > li.active > a:hover {
	color: #52dd4f;
	font-weight: 600;
	text-decoration: underline;
}
.App-new__label {
	margin-left: 33px;
}

@media screen and (min-width: 768px) {
	.review-date {
		float: right;
		margin-left: 20px;
	}

	.leaderboard-nav > li > a {
		font-size: 16px;
		padding: 10px 20px 10px 0px;
	}
}

.dh-table-content {
	padding: 0px;
}

.dh-header {
	position: relative;
	display: flex;
	flex-flow: row nowrap;
}

.dh-header--center {
	justify-content: center;
}

.dh-header > h4 {
	padding: 15px;
	margin: 0px !important;
}

.dh-header > .dh-actions {
	padding: 10px;
	margin-left: auto;
}

.dh-actions__switch-label {
	margin-right: 10px;
}

.dh-actions__switch {
	display: inline-block;
	margin-right: 15px;
	height: 20px;
}

.dh-actions__dropdown {
	display: inline-block;
	margin-right: 15px;
	margin-bottom: 10px;
}

.App__text--nowrap {
	white-space: nowrap;
}
.dh-table {
	margin-bottom: 0px;
}

.dh-table > thead > tr > th:nth-child(1) {
	padding-left: 10px;
}

.dh-table > tbody > tr > td:nth-child(1) {
	padding-left: 10px;
}

.dh-table > tbody > tr > td {
	border: none !important;
}

.dh-table > thead > tr > th {
	border: none !important;
}
.gray-review {
	background-color: #f2f3f4;
	color: #000;
	font-size: 14px;
}
.lightblue-review {
	background-color: #4986f8;
	color: #ffffff;
	font-size: 14px;
}
.blue-review {
	background-color: #000099;
	color: #ffffff;
	font-size: 14px;
}
.custom-review {
	background-color: #8fbdbb;
	color: #ffffff;
	font-size: 14px;
}
.red-review {
	background-color: #d32323;
	color: #ffffff;
	font-size: 14px;
}
.yellow-review {
	background-color: #ffcd00;
	color: #000;
	font-size: 14px;
}
.turquoise-review {
	background-color: #3d999e;
	color: #ffffff;
	font-size: 14px;
}
.pink-review {
	background-color: #ff8580;
	color: #ffffff;
	font-size: 14px;
}
.App__iboxtitle--button {
	margin-top: 19px;
	margin-left: auto;
}

.main-table-margin {
	padding: 20px 10px 40px;
}

.actions-control {
	min-width: 110px;
}
.App__label--font15 {
	font-size: 15px;
}
@media screen and (max-width: 1199px) {
	.App__iboxtitle--button {
		right: 20px;
	}
}
@media screen and (max-width: 1094px) {
	.App__iboxtitle--button {
		right: 40px;
	}
}

@media screen and (max-width: 767px) {
	.App__iboxtitle--button {
		right: 0;
	}
	.main-table-margin {
		padding: 0px;
		margin-left: -15px;
		margin-right: -15px;
	}
}

@media (max-width: 710px) {
	.hidden-xss {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.table-responsive {
		margin-bottom: 0px;
		padding-bottom: 13px;
	}
}

@media (max-width: 768px) {
	.recent-invites-table {
		padding-bottom: 18px;
	}

	#page-wrapper.company-performance,
	.wrapper-content.company-performance,
	.wrapper.company-performance {
		padding-left: 0px;
		padding-right: 0px;
	}
}
@media (max-width: 1046px) {
	.hidden-1046 {
		display: none !important;
	}
}
@media (max-width: 648px) {
	.hidden-xxs {
		display: none !important;
	}
}

@media (max-width: 647px) {
	.replace-xxs {
		display: inline !important;
	}
}

@media (min-width: 648px) {
	.replace-xxs {
		display: none !important;
	}
}

@media (max-width: 552px) {
	.hidden-xxxs {
		display: none !important;
	}
}

@media (min-width: 553px) {
	.replace-xxxs {
		display: none !important;
	}
}

@media (max-width: 420px) {
	.hidden-xxxxs {
		display: none !important;
	}
}

@media (min-width: 420px) {
	.replace-xxxxs {
		display: none !important;
	}
}

@media (min-width: 768px) {
	.replace-xs {
		display: none !important;
	}
}

@media (max-width: 767px) {
	.replace-xs {
		display: inline !important;
	}
}
@media (max-width: 648px) {
	.show-xxxs {
		display: unset !important;
	}
}
.table-curr-page {
	padding-left: 10px;
	padding-right: 10px;
}

.dh-toast {
	font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif !important;
	font-weight: 100;
	font-size: 18px;
	padding-left: 20px !important;
	padding-right: 15px !important;
	background-color: #60a9ff !important;
}

.modal-title {
	font-size: 22px;
}

.reply-name {
	font-weight: 700;
	color: #676a6c;
}

.reply-date {
	color: #999999;
	padding-left: 5px;
}

.reply-with-app {
	color: #118ace;
	padding-left: 5px;
	font-size: 10px;
}

.pagination-bottom {
	padding-top: 10px;
	text-align: center;
}

.heading-control {
	text-align: right;
	padding-bottom: 10px;
	border-bottom: 1px solid #f1f1f1;
	border-bottom-width: 1px;
	border-bottom-style: solid;
	border-bottom-color: rgb(241, 241, 241);
}

.button__reviews {
	margin-top: 20px;
}

.dh-header-control {
	margin-top: 17px;
}

.code-area-hubchat {
	min-height: 150px !important;
}
.code-area {
	min-height: 230px;
	font-family: Courier New, Courier, Lucida Sans Typewriter, Lucida Typewriter, monospace;
}
.col-sm-1__unset-width {
	width: unset;
}

/* Hide HTML5 Up and Down arrows globally. */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}
@media screen and (max-width: 500px) {
	.filter-textbox {
		width: 100% !important;
	}
}

.dh-tip:hover {
	cursor: pointer;
}

.dh-tip:before {
	position: absolute;
	transform: translateY(35px);
	content: attr(tip);
	padding: 8px 10px 5px 10px;
	border-radius: 5px;
	background: #000;
	color: white;
	text-align: center;
	display: none;
	font-size: 12px;
	z-index: 1001;
	height: 30px;
	white-space: nowrap;
}

.dh-tip:hover:before {
	display: block;
}

.dh-tip--large:hover:before {
	white-space: pre-wrap;
	height: auto;
}

.dh-tip--left::before {
	transform: translate(-47px, 35px);
}

.legacy__font {
	font-family: AvenirNext, "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
	color: #333;
}

.ReactModal__Overlay {
	z-index: 200001 !important;
}
